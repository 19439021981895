// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/eileen/Documents/projects/portfolio/portfolio-2019-master/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/eileen/Documents/projects/portfolio/portfolio-2019-master/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/Users/eileen/Documents/projects/portfolio/portfolio-2019-master/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/Users/eileen/Documents/projects/portfolio/portfolio-2019-master/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/Users/eileen/Documents/projects/portfolio/portfolio-2019-master/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-test-js": () => import("/Users/eileen/Documents/projects/portfolio/portfolio-2019-master/src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-work-js": () => import("/Users/eileen/Documents/projects/portfolio/portfolio-2019-master/src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

